import React, { useCallback, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import classnames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useDropzone } from 'react-dropzone'

import Loader from 'components/Loader'

import 'firebase/storage'

const getImageSize = async (url) => {
  const res = await fetch(url)
  const blob = await res.blob()
  const image = new Image()
  image.src = URL.createObjectURL(blob)
  await image.decode()
  return {
    height: image.height,
    width: image.width,
  }
}

export default function ImageDropzone({ value, onChange, withSizes }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback(async (files) => {
    setIsLoading(true)

    try {
      const file = files?.[0]
      const filename = encodeURIComponent(file.name)
      const fileType = encodeURIComponent(file.type)

      const res = await fetch(
        `/api/files/upload-url?file=${filename}&fileType=${fileType}`,
      )
      const { uploadUrl, fileUrl, fields } = await res.json()
      const formData = new FormData()

      Object.entries({ ...fields, file }).forEach(([key, val]) => {
        formData.append(key, val)
      })

      const upload = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
      })

      if (upload.ok) {
        if (withSizes) {
          const { height, width } = await getImageSize(fileUrl)
          onChange({
            height,
            width,
            url: fileUrl,
          })
        } else {
          onChange(fileUrl)
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Upload failed.')
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleDelete = (event) => {
    event.stopPropagation()
    onChange(null)
  }

  const className = classnames(
    'relative flex items-center justify-center w-full rounded-md h-full border p-4 transition duration-200 font-default',
    isDragActive ? 'border-blue-600' : 'border-gray-300',
    value ? '' : 'cursor-pointer',
  )

  return (
    <>
      <div className={className} {...getRootProps()}>
        <input {...getInputProps()} />
        {isLoading ? (
          <>
            <div className="text-blue-600">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {value && (
              <>
                <div
                  style={{
                    backgroundImage: `url("${value}")`,
                  }}
                  className="w-full h-full bg-contain bg-no-repeat bg-center"
                />
                <div className="absolute top-2 right-2 flex flex-col">
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="mb-2 rounded-full bg-white border border-gray-500 p-2 text-gray-500 hover:text-gray-700"
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
              </>
            )}
            {!value && (
              <div>
                {isDragActive ? (
                  <p className="text-center text-sm text-gray-500">
                    {t('imageUpload.drop')}
                  </p>
                ) : (
                  <>
                    <p className="text-center text-sm text-gray-500">
                      {t('imageUpload.drag')}
                    </p>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
